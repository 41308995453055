import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import TopBar from './TopBar';

const classes = {
  root: {
    backgroundColor: 'background.default',
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: '64px'
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
};

function MainLayout({ children }) {
  return (
    <Box sx={classes.root}>
      <TopBar />
      <Box sx={classes.wrapper}>
        <Box sx={classes.contentContainer}>
          <Box sx={classes.content}>
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

MainLayout.propTypes = {
  children: PropTypes.any
};

export default MainLayout;

export const RESET_LOCATION = '@location/reset-location';
export const UPDATE_LOCATION = '@location/update-location';

export function updateLocation(location) {
  const payload = {
    location
  };

  return (dispatch) => {
    dispatch({
      type: UPDATE_LOCATION,
      payload
    });
  };
}

export function resetLocation() {
  return {
    type: RESET_LOCATION
  };
}

import LogRocket from 'src/utils/logrocket';
import logAnalyticsEvent from './logAnalyticsEvent';

const logExceptionError = (error) => {
  const { code, message } = error;

  LogRocket.captureException(error);

  logAnalyticsEvent('error', {
    code,
    message
  });
};

export default logExceptionError;

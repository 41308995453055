/* eslint-disable react/no-array-index-key */
import {
  lazy,
  Suspense,
  Fragment
} from 'react';
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import SessionLayout from 'src/layouts/SessionLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';

const frontBaseHref = process.env.REACT_APP_FRONT_BASE_HREF;
const urlQuery = document.location.search;
const urlHash = document.location.hash;

const routesConfig = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View'))
  },
  {
    exact: true,
    path: '/acctmgmt/__/auth/action',
    component: lazy(() => import('src/views/auth/AccountManagementView'))
  },
  {
    exact: true,
    path: '/terms-conditions',
    component: () => { window.location.assign(`${frontBaseHref}/terms-conditions${urlQuery}${urlHash}`); }
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    guard: GuestGuard,
    exact: true,
    path: '/reset-password',
    component: lazy(() => import('src/views/auth/ResetPasswordView'))
  },
  {
    path: '/session/room/:sessionId',
    guard: AuthGuard,
    layout: SessionLayout,
    routes: [
      {
        exact: true,
        path: [
          '/session/:label/:sessionId?'
        ],
        component: lazy(() => import('src/views/session/SessionView'))
      },
    ]
  },
  {
    exact: true,
    layout: DashboardLayout,
    path: '/skill/:skillId',
    component: lazy(() => import('src/views/skills/SkillDetailsView'))
  },
  {
    exact: true,
    layout: DashboardLayout,
    path: '/learn/browse',
    component: lazy(() => import('src/views/skills/SkillBrowseView'))
  },
  {
    path: '*',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: () => <Redirect to="/reports/dashboard" />
      },
      {
        exact: true,
        path: '/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },
      {
        exact: true,
        path: '/reports/dashboard',
        component: lazy(() => import('src/views/reports/DashboardView'))
      },
      {
        exact: true,
        path: '/calendar',
        component: lazy(() => import('src/views/calendar/CalendarView'))
      },
      {
        exact: true,
        path: [
          '/chat/threads',
          '/chat/thread/:threadKey'
        ],
        component: lazy(() => import('src/views/chat/ChatView'))
      },
      {
        exact: true,
        path: '/chat',
        component: () => <Redirect to="/chat/threads" />
      },
      {
        exact: true,
        path: [
          '/sessions/label/:customLabel/:sessionId?',
          '/sessions/:label/:sessionId?',
          '/session/label/:customLabel/:sessionId?',
          '/session/:label/:sessionId?',
        ],
        component: lazy(() => import('src/views/session/SessionView'))
      },
      {
        exact: true,
        path: [
          '/sessions',
          '/session',
        ],
        component: () => <Redirect to="/sessions/upcoming" />
      },
      {
        exact: true,
        path: '/teach/browse',
        component: lazy(() => import('src/views/skills/MySkillBrowseView'))
      },
      {
        exact: true,
        path: '/teach/create-skill',
        component: lazy(() => import('src/views/skills/SkillCreateView'))
      },
      {
        exact: true,
        path: '/skill/:skillId/update',
        component: lazy(() => import('src/views/skills/SkillCreateView'))
      },
      {
        exact: true,
        path: '/skills',
        component: () => <Redirect to="/learn/browse" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

const renderRoutes = (routes) => (routes ? (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
) : null);

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;

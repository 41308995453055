import React, { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Collapse,
  ListItemButton,
  ListItemIcon, ListItemText
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const classes = {
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: 'text.secondary',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%'
  },
  buttonLeaf: {
    color: 'text.secondary',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: 'fontWeightRegular',
    '&.depth-0': {
      '& $title': {
        fontWeight: 'fontWeightMedium'
      }
    }
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 1
  },
  title: {
    marginRight: 'auto'
  },
  active: {
    color: 'secondary.main',
    '& $title': {
      fontWeight: 'fontWeightMedium'
    },
    '& $icon': {
      color: 'secondary.main'
    }
  }
};

function NavItem({
  title,
  href,
  depth,
  children,
  icon: Icon,
  className,
  open: openProp,
  info: Info,
  ...rest
}) {
  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = { paddingLeft };

  if (children) {
    return (
      <>
        <ListItemButton
          disableGutters
          key={title}
          onClick={handleToggle}
          style={style}
          {...rest}
        >
          {Icon && (
          <ListItemIcon>
            <Icon
              sx={classes.icon}
              size="20"
            />
          </ListItemIcon>
          )}
          <ListItemText sx={classes.title}>
            {title}
          </ListItemText>
          {open ? (
            <ExpandLessIcon
              size="small"
              color="inherit"
            />
          ) : (
            <ExpandMoreIcon
              size="small"
              color="inherit"
            />
          )}
        </ListItemButton>
        <Collapse in={open}>
          {children}
        </Collapse>
      </>
    );
  }

  return (
    <>
      <ListItemButton
        component={RouterLink}
        to={href}
        exact
        disableGutters
        key={title}
        style={style}
        {...rest}
      >
        {Icon && (
          <ListItemIcon>
            <Icon
              sx={classes.icon}
              size="20"
            />
          </ListItemIcon>
        )}

        <ListItemText sx={classes.title}>
          {title}
        </ListItemText>
        {Info && <Info sx={classes.info} />}
      </ListItemButton>
    </>
  );
}

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  info: PropTypes.any,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired
};

NavItem.defaultProps = {
  open: false
};

export default NavItem;

/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  ADD_SKILL,
  UPDATE_SKILL,
  UPDATE_SKILL_PUBLISH_STATUS,
  GET_SKILL,
  GET_SKILLS
} from 'src/actions/skillActions';

const initialState = {
  skill: null
};

const skillReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SKILL: {
      const { skill } = action.payload;

      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.added = skill;
      });
    }

    case UPDATE_SKILL: {
      const { skill } = action.payload;

      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.updated = skill;
      });
    }

    case UPDATE_SKILL_PUBLISH_STATUS: {
      const { skill } = action.payload;

      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.skill = {
          published: !skill
        };
      });
    }

    case GET_SKILL: {
      const { skill } = action.payload;

      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.retrieved = skill;
      });
    }

    case GET_SKILLS: {
      const { skills } = action.payload;

      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.skills = skills;
      });
    }

    default: {
      return state;
    }
  }
};

export default skillReducer;

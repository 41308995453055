import { combineReducers } from 'redux';
import { reducer as simpleWebRtcReducer } from '@andyet/simplewebrtc';
import accountReducer from './accountReducer';
import notificationsReducer from './notificationsReducer';
import chatReducer from './chatReducer';
import skillReducer from './skillReducer';
import sessionReducer from './sessionReducer';
import locationReducer from './locationReducer';

const rootReducer = combineReducers({
  account: accountReducer,
  notifications: notificationsReducer,
  chat: chatReducer,
  session: sessionReducer,
  skill: skillReducer,
  location: locationReducer,
  simplewebrtc: simpleWebRtcReducer
});

export default rootReducer;

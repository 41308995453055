import { analytics } from 'src/utils/firebase';
import { logEvent } from 'firebase/analytics';
import logExceptionError from 'src/utils/logError';

/**
 * Log Google Analytics event
 * @param {string} name - event name
 * @param {Object} [parameters = {}] - event parameters
 */
export default (name, parameters = {}) => {
  try {
    logEvent(analytics, name, parameters);
  } catch (error) {
    logExceptionError(error);
  }
};

import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import Logo from 'src/components/Logo';

const classes = {
  root: {
    alignItems: 'center',
    backgroundColor: 'background.default',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    padding: 3,
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 2000
  },
  logo: {
    width: 200,
    maxWidth: '100%'
  }
};

function SplashScreen() {
  return (
    <Box sx={classes.root}>
      <Box
        display="flex"
        justifyContent="center"
        mb={6}
      >
        <Logo sx={classes.logo} />
      </Box>
      <CircularProgress />
    </Box>
  );
}

export default SplashScreen;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import MainLayout from 'src/layouts/MainLayout';
import NavBar from './NavBar';
import TopBar from './TopBar';

const classes = {
  root: {
    backgroundColor: 'background.dark',
    display: 'flex',
    minHeight: '100vh',
    height: '100vh',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 8,
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
};

function DashboardLayout({ children }) {
  const { user } = useSelector((state) => state.account);
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  if (!user) {
    return (
      <MainLayout>
        {children}
      </MainLayout>
    );
  }

  return (
    <Box sx={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <Box sx={[classes.wrapper, { pl: { lg: 32 } }]}>
        <Box sx={classes.contentContainer}>
          <Box sx={classes.content}>
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.any
};

export default DashboardLayout;

import jwtDecode from 'jwt-decode';
import { instanceAxios as axios } from 'src/utils/axios';
import logExceptionError from 'src/utils/logError';
import { auth } from 'src/utils/firebase';
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail, deleteUser, reauthenticateWithCredential, updatePassword,
  EmailAuthProvider,
  getIdToken,
  signOut,
  fetchSignInMethodsForEmail,
  sendEmailVerification
} from 'firebase/auth';

class AuthService {
  /**
   * Firebase version
   */

  getUserId = async () => {
    try {
      const user = await auth.currentUser;

      if (!user || !user.uid) {
        this.logout();
        return null;
      }

      return user.uid;
    } catch (error) {
      logExceptionError(error);
      this.logout();

      return null;
    }
  }

  getUserInformation = async () => {
    try {
      const user = await auth.currentUser;

      if (!user) {
        this.logout();
        return null;
      }

      return user;
    } catch (error) {
      logExceptionError(error);
      this.logout();

      return null;
    }
  }

  updatePassword = async (currentPassword, newPassword) => {
    try {
      const user = await auth.currentUser;

      const credential = await EmailAuthProvider.credential(
        user.email,
        currentPassword
      );

      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, newPassword);

      return true;
    } catch (error) {
      logExceptionError(error);

      throw error;
    }
  }

  signInWithEmailAndPassword = async (email, password) => {
    try {
      const signInResult = await signInWithEmailAndPassword(auth, email, password);

      return signInResult;
    } catch (error) {
      logExceptionError(error);

      throw error;
    }
  }

  resetPassword = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);

      return true;
    } catch (error) {
      logExceptionError(error);

      throw error;
    }
  }

  handleAuthenticationFirebase = (accessToken) => {
    const localAccessToken = this.getAccessToken();
    let valid = false;

    if (!localAccessToken) {
      return valid;
    }

    const isValidToken = accessToken === localAccessToken;

    if (isValidToken) {
      this.setSession(accessToken);
      valid = true;
    } else {
      this.setSession(null);
    }

    return valid;
  }

  /**
   * Get User ID token from Firebase Auth.
   * @return {null} null response.
   */
   getIdToken = async () => {
     const user = await auth.currentUser;

     try {
       return getIdToken(user, /** force refresh * */ true)
         .then((idToken) => idToken)
         .catch((error) => {
           logExceptionError(error);
         });
     } catch (error) {
       logExceptionError(error);
     }

     return null;
   }

  deleteUser = async () => {
    const user = auth.currentUser;

    try {
      await deleteUser(user);
      return true;
    } catch (error) {
      logExceptionError(error);
      throw error;
    }
  }

  /**
   * Create a new user with email address and password.
   * @param {string} [email] - user's email address (as the username).
   * @param {string} [password] - user's password.
   * @return {Object} Firebase response result after creating a new user.
   */
  createUserWithEmailAndPassword = async (email, password) => {
    try {
      const result = await createUserWithEmailAndPassword(auth, email, password);

      const token = await this.getIdToken();

      this.setSession(token);

      return result;
    } catch (error) {
      logExceptionError(error);

      // Delete User
      this.deleteUser();
      this.logout();

      throw error;
    }
  }

  sendEmailVerification = async () => {
    try {
      sendEmailVerification(auth.currentUser);
    } catch (error) {
      logExceptionError(error);
    }
  }

  fetchSignInMethodsForEmail = (email) => fetchSignInMethodsForEmail(auth, email)
    .then((result) => result) // resolve(result);
    .catch((error) => {
      logExceptionError(error);

      throw error;
    });

  handleAuthentication() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return;
    }

    if (this.isValidToken(accessToken)) {
      this.setSession(accessToken);
    } else {
      this.setSession(null);
    }
  }

  logout = () => {
    this.setSession(null);

    // Preparing for Firebase
    signOut(auth)
      .then(() => {
      // Sign-out successful.
      })
      .catch((error) => {
        logExceptionError(error);
      });
  }

  /**
   * Set user session that also can be used for authorizarion
   * @param {string} [accessToken] - user's access token.
   */
  setSession = (accessToken) => {
    if (accessToken) {
      localStorage.removeItem('accessToken');
      sessionStorage.setItem('accessToken', accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem('accessToken');
      sessionStorage.removeItem('accessToken');
      delete axios.defaults.headers.common.Authorization;
    }
  }

  getAccessToken = () => sessionStorage.getItem('accessToken');

  isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  }

  isAuthenticated = () => !!this.getAccessToken()
}

const authService = new AuthService();

export default authService;

import axios from 'src/utils/axios';
import firestoreService from 'src/services/firestoreService';
import { onSnapshot } from 'firebase/firestore';
import logExceptionError from 'src/utils/logError';
import _ from 'lodash';

export const GET_CONTACTS = '@chat/get-contacts';
export const GET_THREADS = '@chat/get-threads';
export const GET_THREAD = '@chat/get-thread';
export const CREATE_NEW_THREAD = '@chat/create-new-thread';
export const MARK_THREAD_AS_SEEN = '@chat/mark-thread-as-seen';
export const ADD_MESSAGE = '@chat/add-message';
export const OPEN_SIDEBAR = '@chat/open-sidebar';
export const CLOSE_SIDEBAR = '@chat/close-sidebar';
export const FIRST_MESSAGE = '@chat/first-message';
export const DELETE_FIRST_MESSAGE = '@chat/delete-first-message';

// Function to set onSnapshot subscription number
function createFnCounter(fn, invokeBeforeExecution) {
  let count = 0;
  return (args) => {
    count++;
    if (count <= invokeBeforeExecution) {
      return true;
    }
    return fn(args, count);
  };
}

export function getContacts() {
  return async (dispatch) => {
    const request = axios.get('/api/chat/contacts');

    request.then((response) => dispatch({
      type: GET_CONTACTS,
      payload: response.data
    }));
  };
}

export function getThreads(uid) {
  return async (dispatch) => {
    function handleActivitySubscription(snapshot, counter) {
      const initialLoad = counter === 1;

      snapshot.docChanges().forEach((change) => {
        if (change.type === 'modified' && !initialLoad) {
          // DO ADD_MESSAGE here
          const message = change.doc.data();

          dispatch({
            type: ADD_MESSAGE,
            payload: {
              message: { ...message },
              participantIds: message.participantIds
            }
          });
        }
      });
    }

    const handleActivitySubscriptionWithCounter = createFnCounter(handleActivitySubscription, 0);

    try {
      const { result, q } = await firestoreService.getChatThreads(uid);

      const unsubscribeSnapshot = onSnapshot(
        q,
        { includeMetadataChanges: true },
        handleActivitySubscriptionWithCounter
      );

      if (result) {
        const threadsArray = [];

        // First we group the array by threadId
        const groupedThreads = _.groupBy(result, 'threadId');

        // And then we'll loop through and create a 'summary' for what this thread about
        // by sampling to the first item of that thread
        // and push into a new array to pass to redux state
        Object.keys(groupedThreads).forEach((key) => {
          const formattedGroup = {
            key: groupedThreads[key][0].threadId,
            messages: groupedThreads[key],
            type: groupedThreads[key][0].type,
            participantIds: groupedThreads[key][0].participantIds,
            participants: groupedThreads[key][0].participants,
            title: groupedThreads[key][0].title
          };

          threadsArray.push(formattedGroup);
        });

        dispatch({
          type: GET_THREADS,
          payload: { threads: threadsArray }
        });

        return unsubscribeSnapshot;
      }

      return null;
    } catch (error) {
      logExceptionError(error);

      throw error;
    }

    // const request = axios.get('/api/chat/threads');

    // request.then((response) => {
    //   dispatch({
    //     type: GET_THREADS,
    //     payload: response.data
    //   });
    // });
  };
}

export function getThreadMessages(threadKey, uid) {
  return async (dispatch) => {
    function handleActivitySubscription(snapshot, counter) {
      const initialLoad = counter === 1;

      snapshot.docChanges().forEach((change) => {
        if (change.type === 'added' && !initialLoad) {
          // DO ADD_MESSAGE here
          const message = change.doc.data();

          dispatch({
            type: ADD_MESSAGE,
            payload: {
              message: { ...message },
              participantIds: message.participantIds
            }
          });
        }
      });
    }

    const handleActivitySubscriptionWithCounter = createFnCounter(handleActivitySubscription, 0);

    try {
      const { result, q } = await firestoreService.getChatThreadMessages(threadKey, uid);

      if (result) {
        // Start listening to the query.
        // Using Si Le's tutorial to avoid loading initial payload
        // https://medium.com/@stle/firebase-firestore-initial-payload-with-onsnapshot-6471c06888c5
        const unsubscribeSnapshot = onSnapshot(
          q,
          { includeMetadataChanges: true },
          handleActivitySubscriptionWithCounter
        );

        const messageTemplate = result[result.length - 1];

        const threadResult = {
          thread: {
            key: threadKey,
            messages: result,
            type: messageTemplate.type,
            participantIds: messageTemplate.participantIds,
            participants: messageTemplate.participants,
            title: messageTemplate.title
          }
        };

        dispatch({
          type: GET_THREAD,
          payload: threadResult
        });

        return { result, unsubscribeSnapshot };
      }

      return { result: null, unsubscribeSnapshot: null };
    } catch (error) {
      logExceptionError(error);

      throw error;
    }
  };

  // const request = axios.get('/api/chat/thread', {
  //   params: {
  //     threadKey
  //   }
  // });

  // return (dispatch) => {
  //   request.then((response) => {
  //     if (response.data.thread) {
  //       dispatch({
  //         type: GET_THREAD,
  //         payload: response.data
  //       });
  //     }
  //   });
  // };
}

export function createNewThread(newThreadInfo) {
  return async (dispatch) => {
    try {
      const messagesResult = await firestoreService.createNewChatThread(newThreadInfo);

      const threadPlaceholder = {};

      if (messagesResult) {
        threadPlaceholder.thread = {
          messages: messagesResult,
          key: messagesResult.threadId,
          type: messagesResult.type,
          participantIds: messagesResult.participantIds,
          participants: messagesResult.participants
        };
      }

      dispatch({
        type: CREATE_NEW_THREAD,
        payload: threadPlaceholder
      });

      return threadPlaceholder;
    } catch (error) {
      logExceptionError(error);

      throw error;
    }
  };
}

export function markThreadAsSeen(threadKey) {
  const request = axios.get('/api/chat/thread/mark-as-seen', {
    params: {
      threadKey
    }
  });

  return (dispatch) => {
    request.then(() => dispatch({
      type: MARK_THREAD_AS_SEEN,
      payload: {
        threadKey
      }
    }));
  };
}

export function addMessage(newThreadInfo) {
  return async () => {
    try {
      const messagesResult = await firestoreService.addChatThreadMessage(newThreadInfo);

      return messagesResult;
    } catch (error) {
      logExceptionError(error);

      throw error;
    }
  };
}

// export function addMessage({
//   userId,
//   threadKey,
//   body,
//   attachments
// }) {
//   const request = axios.post('/api/chat/messages/new', {
//     userId,
//     threadKey,
//     body,
//     attachments
//   });

//   return (dispatch) => {
//     request.then((response) => dispatch({
//       type: ADD_MESSAGE,
//       payload: {
//         userId,
//         ...response.data
//       }
//     }));
//   };
// }

export function openSidebar() {
  return {
    type: OPEN_SIDEBAR
  };
}

export function closeSidebar() {
  return {
    type: CLOSE_SIDEBAR
  };
}

export function sendFirstMessage(skillInfo, message) {
  return (dispatch) => {
    dispatch({
      type: FIRST_MESSAGE,
      payload: {
        skillInfo,
        message
      }
    });
  };
}

export function deleteFirstMessage() {
  return (dispatch) => {
    dispatch({
      type: DELETE_FIRST_MESSAGE,
      payload: null
    });
  };
}

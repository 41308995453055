import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar, Box, Button, Toolbar
} from '@mui/material';
import Logo from 'src/components/Logo';

const classes = {
  root: {
    backgroundColor: 'background.default'
  },
  toolbar: {
    height: '64px'
  },
  logo: {
    marginRight: 2
  },
  link: {
    fontWeight: 'fontWeightMedium',
    '& + &': {
      marginLeft: 2
    }
  },
  divider: {
    width: '1px',
    height: '32px',
    marginLeft: 2,
    marginRight: 2
  }
};

function TopBar({ className, ...rest }) {
  const { sessionId } = useParams();
  const leaveURL = `/session/details/${sessionId}`;

  return (
    <AppBar
      sx={classes.root}
      color="default"
      {...rest}
    >
      <Toolbar sx={classes.toolbar}>
        <Logo sx={classes.logo} />
        <Box flexGrow={1} />
        <Button
          color="secondary"
          component="a"
          href={leaveURL}
          variant="contained"
          size="small"
        >
          Leave session
        </Button>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;

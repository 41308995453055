import { instanceAxios as axios } from 'src/utils/axios';
import authService from 'src/services/authService';
import logExceptionError from 'src/utils/logError';

// Extra imports
import firestoreService from 'src/services/firestoreService';
import { storage } from 'src/utils/firebase';
import {
  ref, uploadBytes, getDownloadURL, deleteObject
} from 'firebase/storage';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

export const RESET_PASSWORD = '@account/reset-password';
export const RESET_PASSWORD_FAILURE = '@account/reset-password-failure';
export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const LOGIN_FAILURE = '@account/login-failure';
export const SILENT_LOGIN = '@account/silent-login';
export const LOGOUT = '@account/logout';
export const REGISTER = '@account/register';
export const UPDATE_PROFILE = '@account/update-profile'; // later to replace axios
export const DELETE_AVATAR = '@account/delete-avatar';
export const AUTH_STATE_CHANGED = '@account/auth-state-changed';

dayjs.extend(utc);

/**
 * Firebase version
 */

/**
   * Create a new account, registering it in Firebase Auth and Firestore.
   * @param {Object} [data] - Original data from registration field forms.
   */
export function registerUserFirebase(data) {
  return async (dispatch) => {
    try {
      const user = data;
      const { email, password } = data;
      const result = await authService.createUserWithEmailAndPassword(email, password);

      user.avatar = result.user.photoURL || '';

      const userData = await firestoreService.createNewUser(user, result);

      delete user.password;

      authService.sendEmailVerification();

      if (userData) {
        userData.isAuthenticated = true;

        dispatch({
          type: UPDATE_PROFILE,
          payload: {
            user: userData
          }
        });
      }
    } catch (error) {
      logExceptionError(error);

      await authService.deleteUser();

      throw error;
    }
  };
}

export function loginFirebase(email, password) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOGIN_REQUEST });

      const result = await authService.signInWithEmailAndPassword(email, password);
      const { uid } = result.user;
      const accessToken = await authService.getIdToken();

      authService.setSession(accessToken);

      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user: {
            uid,
            isAuthenticated: true,
            accessToken
          }
        }
      });

      return true;
    } catch (error) {
      logExceptionError(error);

      dispatch({ type: LOGIN_FAILURE });
      // dispatch(this.logout());

      throw error;
    }
  };
}

export function resetPassword(email) {
  return async (dispatch) => {
    try {
      dispatch({ type: RESET_PASSWORD });

      const result = await authService.resetPassword(email);

      return result;
    } catch (error) {
      logExceptionError(error);

      dispatch({ type: RESET_PASSWORD_FAILURE });

      throw error;
    }
  };
}

/**
 * Account action to update the currently logged-in user.
 * Both in user Redux and Firestore document
 * @param {Object} [userDetails] - Original data from registration field forms.
 */
export function updateProfileFirebase(userDetails) {
  return async (dispatch) => {
    try {
      const updatedUserInformation = await firestoreService.updateUser(userDetails);

      if (updatedUserInformation) {
        dispatch({
          type: UPDATE_PROFILE,
          payload: {
            user: { ...updatedUserInformation }
          }
        });
      }
    } catch (error) {
      logExceptionError(error);

      throw error;
    }
  };
}

export function uploadAvatar(avatarData, uid) {
  return async (dispatch) => {
    let avatarRef;
    try {
      avatarRef = ref(storage, `avatars/${uid}/avatar`);
      await uploadBytes(avatarRef, avatarData);

      const avatarURL = await getDownloadURL(avatarRef);
      const result = await axios.post('/api/users/avatar/upload', { uid, avatarURL });

      if (result.status !== 200) {
        await deleteObject(avatarRef);

        return false;
      }

      dispatch({
        type: UPDATE_PROFILE,
        payload: {
          user: {
            avatar: avatarURL
          }
        }
      });

      return true;
    } catch (error) {
      logExceptionError(error);
      await deleteObject(avatarRef);

      throw error;
    }
  };
}

export function deleteAvatar(avatarURL, uid) {
  return async (dispatch) => {
    try {
      const result = await axios.delete(`/api/users/avatar/${uid}`);

      if (result.status !== 200) return false;

      dispatch({
        type: DELETE_AVATAR
      });

      return true;
    } catch (error) {
      logExceptionError(error);

      throw error;
    }
  };
}

export function setAuthentication(isAuthenticated, accessToken) {
  return (dispatch) => {
    try {
      dispatch({
        type: AUTH_STATE_CHANGED,
        payload: {
          user: {
            isAuthenticated,
            accessToken
          }
        }
      });
    } catch (error) {
      logExceptionError(error);
    }
  };
}

export function setUserData(user) {
  return (dispatch) => dispatch({
    type: SILENT_LOGIN,
    payload: {
      user
    }
  });
}

export function logout() {
  return async (dispatch) => {
    authService.logout();

    dispatch({
      type: LOGOUT
    });
  };
}

import firestoreService from 'src/services/firestoreService';
import logExceptionError from 'src/utils/logError';

export const GET_NOTIFICATIONS = '@notifications/get-notifications';

export function getNotifications() {
  return async (dispatch) => {
    try {
      const notificationResults = await firestoreService.getNotifications();

      if (notificationResults !== null) {
        dispatch({
          type: GET_NOTIFICATIONS,
          payload: notificationResults
        });
      }
    } catch (error) {
      logExceptionError(error);
    }
  };
}

import axios from 'axios';

const instance = axios.create();
const baseURL = process.env.REACT_APP_FIREBASE_FUNCTIONS_BASE_URL;

export const instanceAxios = axios.create({
  baseURL
});

export default instance;

import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { SnackbarProvider } from 'notistack';
import {
  ThemeProvider, StyledEngineProvider, CssBaseline, adaptV4Theme
} from '@mui/material';
import { jssPreset, StylesProvider } from '@mui/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Auth from 'src/components/Auth';
// import CookiesNotification from 'src/components/CookiesNotification';
// import SettingsNotification from 'src/components/SettingsNotification';
// import GoogleAnalytics from 'src/components/GoogleAnalytics';
// import TawkTo from 'src/components/TawkTo';
import ScrollReset from 'src/components/ScrollReset';
import useSettings from 'src/hooks/useSettings';
import { createTheme } from 'src/theme';
import Routes from 'src/Routes';

const history = createBrowserHistory();
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

function App() {
  const { settings } = useSettings();

  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={createTheme(adaptV4Theme(settings))}>
          <CssBaseline />
          <StylesProvider jss={jss}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <SnackbarProvider maxSnack={1}>
                <Router history={history}>
                  <Auth>
                    <ScrollReset />
                    {/* <GoogleAnalytics /> */}
                    {/* <CookiesNotification /> */}
                    {/* <SettingsNotification /> */}
                    {/* <TawkTo /> */}
                    <Routes />
                  </Auth>
                </Router>
              </SnackbarProvider>
            </LocalizationProvider>
          </StylesProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
}

export default App;

import { auth, db } from 'src/utils/firebase';
import {
  collection,
  doc,
  addDoc,
  setDoc,
  getDoc,
  getDocs,
  updateDoc,
  serverTimestamp,
  query,
  where,
  orderBy,
} from 'firebase/firestore';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { colors } from '@mui/material';

// Extra import
import authService from 'src/services/authService';
import logExceptionError from 'src/utils/logError';
import getCountryCurrency from 'src/utils/getCountryCurrency';

dayjs.extend(utc);

class FirestoreService {
  /**
   * Account or User Related
   */

  /**
   * Retrieve a user information from Firestore
   * @param {string} [userId] - User ID
   * @return {Object|null} User information from Firestore, or null if no information found
   */
  getUserInformation = async (userId) => {
    try {
      const docRef = doc(db, 'users', userId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const { uid, ...data } = docSnap.data();

        return data;
      }

      return null;
    } catch (error) {
      logExceptionError(error);

      throw error;
    }
  };

  /**
   * Update the currently logged-in user document in Firebase Firestore.
   * @param {Object} [userDetails] - Original data from registration field forms.
   * @return {Object|null} User information from Firestore, or null if no information found.
   */
  updateUser = async (userDetails) => {
    try {
      const user = auth.currentUser;
      const userDocRef = doc(db, 'users', user.uid);

      await updateDoc(userDocRef, {
        ...userDetails,
        updatedAt: dayjs.utc().format(),
        updatedAtServer: serverTimestamp(),
      });

      const updatedUserInformation = await this.getUserInformation(user.uid);

      if (!updatedUserInformation) return null;

      return updatedUserInformation;
    } catch (error) {
      logExceptionError(error);

      throw error;
    }
  };

  /**
   * Create a new user document in Firebase Firestore, usually after registration.
   * @param {Object} [userDetails] - Original data from registration field forms.
   * @param {Object} [extraData] - Extra data from Firebase Auth response result.
   * @return {Object|null} User information from Firestore, or null if no information found.
   */
  createNewUser = async (userDetails, extraData) => {
    const {
      firstName,
      lastName,
      avatar,
      country,
      state,
      suburb,
      postcode,
      location,
      latitude,
      longitude,
      locationId,
    } = userDetails;
    const { user } = extraData;
    const { uid, email } = user;

    const { code: currencyCode, symbol: currencySymbol } = await getCountryCurrency(country);

    try {
      await setDoc(
        doc(db, 'users', uid),
        {
          email,
          firstName,
          lastName,
          avatar,
          createdAt: dayjs.utc().format(),
          createdAtServer: serverTimestamp(),
          onboarded: false,
          locationId: locationId || '',
          longitude: longitude || 0,
          latitude: latitude || 0,
          location: location || '',
          country: country || '',
          state: state || '',
          suburb: suburb || '',
          postcode: postcode || '',
          currencyCode: currencyCode || '',
          currencySymbol: currencySymbol || '',
        },
        { merge: true }
      );

      const userData = await this.getUserInformation(uid);

      if (userData) {
        userData.uid = uid;

        return userData;
      }

      return null;
    } catch (error) {
      logExceptionError(error);

      // Delete User
      authService.deleteUser();

      throw error;
    }
  };

  /**
   * Skill related
   */

  addSkill = async (skillInformation, uid) => {
    try {
      // Get user info first to include later in the skills document
      const userInformation = await this.getUserInformation(uid);

      if (userInformation === null) {
        throw new Error('@firestoreService-addSkill/user-not-found');
      }

      const time = dayjs.utc().format();
      const timeServer = serverTimestamp();

      const docRef = await addDoc(collection(db, 'skills'), {
        createdAt: time,
        createdAtServer: timeServer,
        updatedAt: time,
        updatedAtServer: timeServer,
        totalEnrolments: 0,
        totalReviews: 0,
        averageRating: 0,
        published: false,
        owner: {
          uid,
          firstName: userInformation.firstName,
          lastName: userInformation.lastName,
          avatar: userInformation.avatar,
          country: userInformation.country,
          state: userInformation.state,
          suburb: userInformation.suburb,
          currencyCode: userInformation.currencyCode,
          currencySymbol: userInformation.currencySymbol,
          latitude: userInformation.latitude,
          location: userInformation.location,
          longitude: userInformation.longitude,
          postcode: userInformation.postcode,
        },
        ...skillInformation,
      });

      if (docRef) return docRef.id;

      return null;
    } catch (error) {
      logExceptionError(error);

      throw error;
    }
  };

  updateSkill = async (skillInformation, skillId, uid) => {
    try {
      // Get user information
      const userInformation = await this.getUserInformation(uid);

      if (userInformation === null) {
        throw new Error('@firestoreService-updateSkill/user-not-found');
      }

      const skillDocRef = doc(db, 'skills', skillId);

      await updateDoc(skillDocRef, {
        ...skillInformation,
        updatedAt: dayjs.utc().format(),
        updatedAtServer: serverTimestamp(),
        owner: {
          uid,
          firstName: userInformation.firstName,
          lastName: userInformation.lastName,
          avatar: userInformation.avatar,
          country: userInformation.country,
          state: userInformation.state,
          suburb: userInformation.suburb,
          currencyCode: userInformation.currencyCode,
          currencySymbol: userInformation.currencySymbol,
          latitude: userInformation.latitude,
          location: userInformation.location,
          longitude: userInformation.longitude,
          postcode: userInformation.postcode,
        },
      });

      return true;
    } catch (error) {
      logExceptionError(error);

      throw error;
    }
  };

  updateSkillPublishStatus = async (skillId, flag) => {
    try {
      const skillDocRef = doc(db, 'skills', skillId);
      await updateDoc(skillDocRef, {
        published: flag,
        updatedAt: dayjs.utc().format(),
        updatedAtServer: serverTimestamp(),
      });

      return true;
    } catch (error) {
      logExceptionError(error);

      throw error;
    }
  };

  updateSkillOwner = async (uid, data) => {
    try {
      const skillsQuery = query(collection(db, 'skills'), where('owner.uid', '==', uid));
      const querySnapshot = await getDocs(skillsQuery);

      if (querySnapshot.empty) return null;

      querySnapshot.forEach(async (skillRef) => {
        const skillDocRef = doc(db, 'skills', skillRef.id);

        await updateDoc(skillDocRef, {
          updatedAt: dayjs.utc().format(),
          updatedAtServer: serverTimestamp(),
          'owner.uid': uid,
          'owner.firstName': data.firstName,
          'owner.lastName': data.lastName,
          'owner.country': data.country,
          'owner.state': data.state,
          'owner.suburb': data.suburb,
          'owner.postcode': data.postcode,
        });
      });

      return true;
    } catch (error) {
      logExceptionError(error);

      throw error;
    }
  };

  // Get a unique skill
  getSkill = async (skillId) => {
    try {
      const docRef = doc(db, 'skills', skillId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) return docSnap.data();

      // doc.data() will be undefined in this case
      return null;
    } catch (error) {
      logExceptionError(error);

      throw error;
    }
  };

  /**
   * Get list of skills owned by self.
   * @param {string} [userId] - The user ID of the person requesting the list.
   * @return {Array} List of retrived skills and can be empty [].
   */
  getMySkills = async (userId) => {
    try {
      const querySnapshot = await getDocs(collection(db, 'skills'));

      if (querySnapshot.empty) return [];

      // Include doc ID
      const docs = querySnapshot.docs.map((queryDoc) => {
        const newData = queryDoc.data();
        newData.skillId = queryDoc.id;
        return newData;
      });

      // Filter only own skills
      const result = docs.filter((docItem) => docItem.owner.uid === userId);

      return result;
    } catch (error) {
      logExceptionError(error);

      throw error;
    }
  };

  /**
   * Get list of skills owned by other people.
   * @param {string} [userId] - The user ID of the person requesting the list.
   * @return {Array} List of retrived skills and can be empty [].
   */
  getOtherSkills = async (userId) => {
    try {
      const skillsQuery = query(collection(db, 'skills'), where('published', 'in', [true]));
      const querySnapshot = await getDocs(skillsQuery);

      if (querySnapshot.empty) return [];

      // Include Doc ID
      const docs = querySnapshot.docs.map((docRef) => {
        const newData = docRef.data();
        newData.skillId = docRef.id;
        return newData;
      });

      // Filter only other people skills
      const result = docs.filter((docRef) => docRef.owner.uid !== userId);

      return result;
    } catch (error) {
      logExceptionError(error);

      throw error;
    }
  };

  /**
   * Get list of all published skills.
   * @return {Array} List of retrived skills and can be empty [].
   */
  getPublishedSkills = async () => {
    try {
      const skillsQuery = query(collection(db, 'skills'), where('published', 'in', [true]));
      const querySnapshot = await getDocs(skillsQuery);

      if (querySnapshot.empty) return [];

      // Include Doc ID
      const result = querySnapshot.docs.map((docRef) => {
        const newData = docRef.data();
        newData.skillId = docRef.id;
        return newData;
      });

      return result;
    } catch (error) {
      logExceptionError(error);

      throw error;
    }
  };

  /**
   * Sessions related
   */

  addLearningSession = async (learningScheduleInformation) => {
    try {
      const time = dayjs.utc().format();
      const timeServer = serverTimestamp();

      const docRef = await addDoc(collection(db, 'sessions'), {
        ...learningScheduleInformation,
        status: 'requesting',
        createdAt: time,
        createdAtServer: timeServer,
        updatedAt: time,
        updatedAtServer: timeServer,
      });

      if (docRef.id) return docRef.id;

      return null;
    } catch (error) {
      logExceptionError(error);

      throw error;
    }
  };

  getAllLearningSchedules = async (uid) => {
    try {
      const sessionsQuery = query(
        collection(db, 'sessions'),
        where('requestorId', 'in', [uid]),
        orderBy('updatedAt', 'asc')
      );

      const sessionsSnapshot = await getDocs(sessionsQuery);

      if (sessionsSnapshot.empty) return null;

      const docs = sessionsSnapshot.docs.map((docRef) => {
        const data = { ...docRef.data() };
        data.id = docRef.id;
        data.color = colors.green['700'];
        data.title = `To learn: ${data.skillTitle} from ${data.authorFullName}`;
        data.description = data.objective;
        data.start = dayjs(data.startTime).toDate().getTime();
        data.end = dayjs(data.endTime).toDate().getTime();
        return data;
      });

      return docs;
    } catch (error) {
      logExceptionError(error);

      throw error;
    }
  };

  getAllTeachingSchedules = async (uid, status) => {
    try {
      if (!uid) return null;

      const sessionsCollection = collection(db, 'sessions');
      let snapshot;

      if (status && status === 'requesting') {
        const q = query(
          sessionsCollection,
          where('skillOwnerId', '==', uid),
          where('status', '==', status),
          orderBy('updatedAt', 'asc')
        );

        snapshot = await getDocs(q);
      } else {
        const q = query(
          sessionsCollection,
          where('skillOwnerId', 'in', [uid]),
          orderBy('updatedAt', 'asc')
        );

        snapshot = await getDocs(q);
      }

      if (snapshot.empty) return null;

      const result = snapshot.docs.map((docRef) => {
        const data = { ...docRef.data() };
        data.id = docRef.id;
        data.color = colors.blue['700'];
        data.title = `To teach: ${data.skillTitle} to ${data.requestorFullName}`;
        data.description = data.objective;
        data.start = dayjs(data.startTime).toDate().getTime();
        data.end = dayjs(data.endTime).toDate().getTime();
        return data;
      });

      return result;
    } catch (error) {
      logExceptionError(error);

      throw error;
    }
  };

  getSessionById = async (sessionId) => {
    try {
      const sessionRef = doc(db, 'sessions', sessionId);
      const sessionSnap = await getDoc(sessionRef);

      if (!sessionSnap.exists()) return null;

      const result = sessionSnap.data();
      result.id = sessionId;

      return result;
    } catch (error) {
      logExceptionError(error);

      throw error;
    }
  };

  updateSessionStatus = async (sessionId, status) => {
    try {
      const time = dayjs.utc().format();
      const timeServer = serverTimestamp();

      const sessionDocRef = doc(db, 'sessions', sessionId);

      await updateDoc(sessionDocRef, {
        status,
        updatedAt: time,
        updatedAtServer: timeServer,
      });

      return true;
    } catch (error) {
      logExceptionError(error);

      throw error;
    }
  };

  updateSessionReview = async (sessionId, session, rating, review) => {
    try {
      const time = dayjs.utc().format();
      const timeServer = serverTimestamp();

      const sessionDocRef = doc(db, 'sessions', sessionId);

      await updateDoc(sessionDocRef, {
        rating: Number(rating),
        review,
        status: 'completed',
        updatedAt: time,
        updatedAtServer: timeServer,
      });

      await this.updateReview(sessionId, session, rating, review);

      return true;
    } catch (error) {
      logExceptionError(error);

      throw error;
    }
  };

  addSessionPaymentAmount = async (paymentInfo) => {
    try {
      const time = dayjs.utc().format();
      const timeServer = serverTimestamp();

      const { sessionId, paymentAmount, paymentCurrencyCode } = paymentInfo;

      const sessionDocRef = doc(db, 'sessions', sessionId);

      await updateDoc(sessionDocRef, {
        paymentAmount,
        paymentCurrencyCode,
        updatedAt: time,
        updatedAtServer: timeServer,
      });

      return true;
    } catch (error) {
      logExceptionError(error);

      throw error;
    }
  };

  /**
   *
   * Reviews related
   */

  updateReview = async (sessionId, session, rating, review) => {
    try {
      const time = dayjs.utc().format();
      const timeServer = serverTimestamp();

      await setDoc(
        doc(db, 'reviews', sessionId),
        {
          skillId: session.skillId,
          skillTitle: session.skillTitle,
          reviewerId: session.requestorId,
          reviewerFullName: session.requestorFullName,
          rating: Number(rating),
          review,
          updatedAt: time,
          updatedAtServer: timeServer,
        },
        { merge: true }
      );

      return true;
    } catch (error) {
      logExceptionError(error);

      throw error;
    }
  };

  getReviews = async (skillId) => {
    try {
      const reviewsQuery = query(
        collection(db, 'reviews'),
        where('skillId', '==', skillId),
        orderBy('updatedAt', 'asc')
      );

      const reviewsSnapshot = await getDocs(reviewsQuery);

      if (reviewsSnapshot.empty) return null;

      const result = [];

      // result = snapshot.docs.map((doc) => doc.data());

      reviewsSnapshot.forEach((item) => {
        const tempResult = {
          id: item.id,
          ...item.data(),
        };
        result.push(tempResult);
      });

      return result;
    } catch (error) {
      logExceptionError(error);

      throw error;
    }
  };

  /**
   * Chat Related
   */

  // Get all chat threads
  getChatThreads = async (uid) => {
    try {
      const q = query(
        collection(db, 'chat_threads'),
        where('participantIds', 'array-contains', uid),
        orderBy('sentAt', 'asc')
      );

      const snapshot = await getDocs(q);
      let result = null;

      if (!snapshot.empty) {
        result = snapshot.docs.map((item) => item.data());
      }

      return { result, q };
    } catch (error) {
      logExceptionError(error);

      throw error;
    }
  };

  // Get chat threads that only related to a particular skill ID
  // and where the user is a participant of that thread
  getChatThreadMessages = async (threadId, uid) => {
    try {
      // Using Firestore composite index
      const q = query(
        collection(db, 'chat_messages'),
        where('threadId', 'in', [threadId]),
        where('participantIds', 'array-contains', uid),
        orderBy('sentAt', 'asc')
      );

      const snapshot = await getDocs(q);

      let result = null;

      if (!snapshot.empty) {
        result = snapshot.docs.map((item) => item.data());
      }

      return { result, q };
    } catch (error) {
      logExceptionError(error);

      throw error;
    }
  };

  // Get chat thread information by thread ID
  getChatThread = async (threadId) => {
    try {
      const docRef = doc(db, 'chat_threads', threadId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) return true;

      return null;
    } catch (error) {
      logExceptionError(error);

      throw error;
    }
  };

  // Create a new chat thred
  createNewChatThread = async (newThreadInfo) => {
    try {
      const time = dayjs.utc().format();
      const timeServer = serverTimestamp();

      // First create a new thread on chat_threads collection
      await setDoc(doc(db, 'chat_threads', newThreadInfo.threadId), {
        ...newThreadInfo,
        createdAt: time,
        createdAtServer: timeServer,
        sentAt: time,
        sentAtServer: timeServer,
      });

      // Then create into chat_messages collection
      const chatMessageRef = await addDoc(collection(db, 'chat_messages'), {
        ...newThreadInfo,
        sentAt: time,
        sentAtServer: timeServer,
      });

      if (chatMessageRef.id) {
        return { ...newThreadInfo, sentAt: time };
      }

      // doc.data() will be undefined in this case
      return null;
    } catch (error) {
      logExceptionError(error);

      throw error;
    }
  };

  // Add message to the chatThread
  addChatThreadMessage = async (newThreadInfo) => {
    try {
      const time = dayjs.utc().format();
      const timeServer = serverTimestamp();

      const threadDocRef = doc(db, 'chat_threads', newThreadInfo.threadId);

      await updateDoc(threadDocRef, {
        body: newThreadInfo.body,
        senderId: newThreadInfo.senderId,
        sentAt: time,
        sentAtServer: timeServer,
      });

      const docRef = await addDoc(collection(db, 'chat_messages'), {
        ...newThreadInfo,
        sentAt: time,
        sentAtServer: timeServer,
      });

      if (docRef.id) return { ...newThreadInfo, sentAt: time };

      // doc.data() will be undefined in this case
      return null;
    } catch (error) {
      logExceptionError(error);

      throw error;
    }
  };

  /**
   * Notifications Related
   */

  // Send notification
  sendNotification = async (notificationDetails) => {
    try {
      const time = dayjs.utc().format();
      const timeServer = serverTimestamp();

      const res = await addDoc(collection(db, 'notifications'), {
        createdAt: time,
        createdAtServer: timeServer,
        read: false,
        ...notificationDetails,
      });

      if (res.id) {
        return res;
      }

      return null;
    } catch (error) {
      logExceptionError(error);

      throw error;
    }
  };

  // Get notifications
  getNotifications = async () => {
    try {
      const uid = await authService.getUserId();

      if (!uid) return null;

      const q = query(
        collection(db, 'notifications'),
        where('recipientId', '==', uid),
        orderBy('createdAt', 'asc')
      );

      const snapshot = await getDocs(q);

      if (snapshot.empty) return null;

      const docs = snapshot.docs.map((item) => {
        const newData = item.data();
        newData.id = item.id;
        return newData;
      });

      return docs;
    } catch (error) {
      logExceptionError(error);

      throw error;
    }
  };
}

const firestoreService = new FirestoreService();

export default firestoreService;

import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar, Box, Hidden, IconButton, Toolbar, SvgIcon
} from '@mui/material';
import { Menu as MenuIcon } from 'react-feather';
import Logo from 'src/components/Logo';
import { THEMES } from 'src/constants';
import Account from './Account';
// import Notifications from './Notifications';
// import Search from './Search';
// import Settings from './Settings';

function TopBar({
  className,
  onMobileNavOpen,
  ...rest
}) {
  return (
    <AppBar
      sx={
        {
          zIndex: 'modal',
          backgroundColor: (theme) => {
            if (theme.name === THEMES.LIGHT) return 'primary.main';
            if (theme.name === THEMES.ONE_DARK) return 'background.default';
            if (theme.name === THEMES.SIMPLE) return 'background.default';
            return {};
          },
          boxShadow: (theme) => {
            if (theme.name === THEMES.LIGHT) return 'none';
            return {};
          }
        }
      }
      {...rest}
    >
      <Toolbar sx={{ minHeight: 64 }}>
        <Hidden lgUp>
          <IconButton
            // className={classes.menuButton}
            color="inherit"
            onClick={onMobileNavOpen}
            data-onboarding="main-menu"
            size="large"
          >
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden lgDown>
          <RouterLink to="/">
            <Logo />
          </RouterLink>
        </Hidden>
        <Box
          ml={2}
          flexGrow={1}
        />
        {/* <Search /> */}
        {/* <Notifications /> */}
        {/* <Settings /> */}
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;

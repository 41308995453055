import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import LogRocket from 'src/utils/logrocket';
import mask from 'src/utils/mask';

function AuthGuard({ children }) {
  const { user } = useSelector((state) => state.account);
  const location = useLocation();

  if (!user || !user.uid) {
    return <Redirect to={{ pathname: '/login', state: { referrer: location.pathname } }} />;
  }

  if (user.uid) {
    let name = '';

    if (user.firstName && user.lastName) name = `${user.firstName} ${user.lastName}`;

    const maskedName = mask(name);

    LogRocket.identify(user.uid, {
      maskedName
    });
  }

  return children;
}

AuthGuard.propTypes = {
  children: PropTypes.any
};

export default AuthGuard;

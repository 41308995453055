/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GET_LABELS,
  GET_SESSIONS,
  DELETE_SESSIONS,
  GET_SESSION,
  GET_SCHEDULES,
  GET_SCHEDULE,
  OPEN_SIDEBAR,
  CLOSE_SIDEBAR,
  OPEN_NEW_MESSAGE,
  CLOSE_NEW_MESSAGE,
  REQUEST_SESSION_PAYMENT
} from 'src/actions/sessionActions';
import objFromArray from 'src/utils/objFromArray';

const initialState = {
  sessions: {
    byId: {},
    allIds: []
  },
  labels: [],
  sidebarOpen: false,
  newMessageOpen: false
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LABELS: {
      const { labels } = action.payload;

      return produce(state, (draft) => {
        draft.labels = labels;
      });
    }

    case GET_SESSIONS: {
      const { sessions } = action.payload;

      return produce(state, (draft) => {
        draft.sessions.byId = objFromArray(sessions);
        draft.sessions.allIds = Object.keys(draft.sessions.byId);
      });
    }

    case DELETE_SESSIONS: {
      return produce(state, (draft) => {
        draft.sessions.byId = {};
        draft.sessions.allIds = [];
      });
    }

    case GET_SESSION: {
      const { session } = action.payload;

      return produce(state, (draft) => {
        draft.sessions.byId[session.id] = session;

        if (!draft.sessions.allIds.includes(session.id)) {
          draft.sessions.allIds.push(session.id);
        }
      });
    }

    case GET_SCHEDULES: {
      const { schedules } = action.payload;

      return produce(state, (draft) => {
        draft.schedules.byId = objFromArray(schedules);
        draft.schedules.allIds = Object.keys(draft.schedules.byId);
      });
    }

    case GET_SCHEDULE: {
      const { schedule } = action.payload;

      return produce(state, (draft) => {
        draft.schedules.byId[schedule.id] = schedule;

        if (!draft.schedules.allIds.includes(schedule.id)) {
          draft.schedules.allIds.push(schedule.id);
        }
      });
    }

    case OPEN_SIDEBAR: {
      return produce(state, (draft) => {
        draft.sidebarOpen = true;
      });
    }

    case CLOSE_SIDEBAR: {
      return produce(state, (draft) => {
        draft.sidebarOpen = false;
      });
    }

    case OPEN_NEW_MESSAGE: {
      return produce(state, (draft) => {
        draft.newMessageOpen = true;
      });
    }

    case CLOSE_NEW_MESSAGE: {
      return produce(state, (draft) => {
        draft.newMessageOpen = false;
      });
    }

    case REQUEST_SESSION_PAYMENT: {
      const { sessionId, paymentAmount, paymentCurrencyCode } = action.payload;
      return produce(state, (draft) => {
        draft.sessions.byId[sessionId].paymentCurrencyCode = paymentCurrencyCode;
        draft.sessions.byId[sessionId].paymentAmount = paymentAmount;
        draft.sessions.byId[sessionId].paymentStatus = 'requesting';
      });
    }

    default: {
      return state;
    }
  }
};

export default sessionReducer;

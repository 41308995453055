import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import LogRocket from 'src/utils/logrocket';
import mask from 'src/utils/mask';

function GuestGuard({ children }) {
  const { user } = useSelector((state) => state.account);
  const location = useLocation();
  const referrerUrl = location.state && location.state.referrer;

  if (user && user.uid) {
    let name = '';

    if (user.firstName && user.lastName) name = `${user.firstName} ${user.lastName}`;

    const maskedName = mask(name);

    LogRocket.identify(user.uid, {
      maskedName
    });

    if (referrerUrl) return <Redirect to={referrerUrl} />;

    return <Redirect to="/reports/dashboard" />;
  }

  return children;
}

GuestGuard.propTypes = {
  children: PropTypes.any
};

export default GuestGuard;

import { instanceAxios as axios } from 'src/utils/axios';
import firestoreService from 'src/services/firestoreService';
import logAnalyticsEvent from 'src/utils/logAnalyticsEvent';
import logExceptionError from 'src/utils/logError';
import { colors } from '@mui/material';

export const GET_LABELS = '@session/get-labels';
export const GET_SESSIONS = '@session/get-sessions';
export const DELETE_SESSIONS = '@session/delete-sessions';
export const GET_SESSION = '@session/get-session';
export const GET_SCHEDULES = '@session/get-schedules';
export const GET_SCHEDULE = '@session/get-schedule';
export const OPEN_SIDEBAR = '@session/open-sidebar';
export const CLOSE_SIDEBAR = '@session/close-sidebar';
export const OPEN_NEW_MESSAGE = '@session/open-new-message';
export const CLOSE_NEW_MESSAGE = '@session/close-new-message';
export const REQUEST_SESSION_PAYMENT = '@session/request-session-payment';

export function getLabels() {
  const payload = {
    labels: [
      {
        id: 'upcoming',
        type: 'system_label',
        name: 'Upcoming'
      },
      {
        id: 'confirming',
        type: 'system_label',
        name: 'Please confirm'
      },
      {
        id: 'requesting',
        type: 'custom_label',
        name: 'Requesting',
        color: colors.orange[600]
      },
      {
        id: 'reviewing',
        type: 'custom_label',
        name: 'Leave a review',
        color: colors.purple[600]
      },
      {
        id: 'completed',
        type: 'custom_label',
        name: 'Completed',
        color: colors.green[600]
      }
    ]
  };

  return (dispatch) => {
    dispatch({
      type: GET_LABELS,
      payload
    });
  };
}

export function getMails(params) {
  const request = axios.get('/api/mail/mails', {
    params
  });

  return (dispatch) => {
    request.then((response) => dispatch({
      type: GET_SCHEDULES,
      payload: response.data
    }));
  };
}

export function getSessions(uid, category) {
  return async (dispatch) => {
    try {
      let type;
      let label;

      switch (category) {
        case 'upcoming':
          label = 'confirmed';
          break;

        case 'requesting':
          type = 'requestor';
          label = 'requesting';
          break;

        case 'confirming':
          type = 'skillOwner';
          label = 'requesting';
          break;

        case 'reviewing':
          type = 'requestor';
          label = 'reviewing';
          break;

        case 'completed':
          label = 'completed';
          break;

        default:
          type = 'undefined';
          label = category;
      }

      const response = await axios.get('/api/sessions', {
        params: {
          uid,
          type,
          label
        }
      });

      if (!response || response.data.length === 0 || response.status !== 200) {
        dispatch({ type: DELETE_SESSIONS });

        return null;
      }

      const payload = {
        sessions: response.data
      };

      dispatch({
        type: GET_SESSIONS,
        payload
      });

      return response.data;
    } catch (error) {
      const { code, message } = error;

      logExceptionError(error);

      logAnalyticsEvent('error', {
        code,
        message
      });

      dispatch({ type: DELETE_SESSIONS });
    }

    return null;
  };
}

export const getSessionById = (sessionId) => async (dispatch) => {
  try {
    const session = await firestoreService.getSessionById(sessionId);

    if (session !== null) {
      dispatch({
        type: GET_SESSION,
        payload: {
          session
        }
      });

      return session;
    }

    return null;
  } catch (error) {
    logExceptionError(error);

    throw error;
  }
};

export const updateSessionStatus = (sessionId, status) => async () => {
  try {
    const result = await firestoreService.updateSessionStatus(sessionId, status);

    return result;
  } catch (error) {
    logExceptionError(error);

    throw error;
  }
};

export const updateSessionReview = (sessionId, session, rating, review) => async () => {
  try {
    const result = await firestoreService.updateSessionReview(sessionId, session, rating, review);

    return result;
  } catch (error) {
    logExceptionError(error);

    throw error;
  }
};

export const getReviews = (skillId) => async () => {
  try {
    const result = await firestoreService.getReviews(skillId);

    return result;
  } catch (error) {
    logExceptionError(error);

    throw error;
  }
};

export async function getAllLearningSchedules(uid) {
  try {
    const result = await firestoreService.getAllLearningSchedules(uid);

    return result;
  } catch (error) {
    logExceptionError(error);

    throw error;
  }
}

export async function getAllTeachingSchedules(uid, status = null) {
  try {
    const result = await firestoreService.getAllTeachingSchedules(uid, status);

    return result;
  } catch (error) {
    logExceptionError(error);

    throw error;
  }
}

export function requestSessionPayment(paymentInfo) {
  return async (dispatch) => {
    try {
      const isSuccess = await firestoreService.addSessionPaymentAmount(paymentInfo);

      if (!isSuccess) return false;

      dispatch({
        type: REQUEST_SESSION_PAYMENT,
        payload: {
          sessionId: paymentInfo.sessionId,
          paymentAmount: paymentInfo.paymentAmount,
          paymentCurrencyCode: paymentInfo.paymentCurrencyCode
        }
      });

      return true;
    } catch (error) {
      logExceptionError(error);

      throw error;
    }
  };
}

export function openSidebar() {
  return {
    type: OPEN_SIDEBAR
  };
}

export function closeSidebar() {
  return {
    type: CLOSE_SIDEBAR
  };
}

export function openNewMessage() {
  return {
    type: OPEN_NEW_MESSAGE
  };
}

export function closeNewMessage() {
  return {
    type: CLOSE_NEW_MESSAGE
  };
}

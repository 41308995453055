import React, {
  useEffect,
  useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useQuery from 'src/hooks/useQuery';
import PropTypes from 'prop-types';
import SplashScreen from 'src/components/SplashScreen';
import { setUserData, logout, setAuthentication } from 'src/actions/accountActions';
import Cookies from 'js-cookie';

// Extra imports
import authService from 'src/services/authService';
import { auth } from 'src/utils/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import firestoreService from 'src/services/firestoreService';
import logExceptionError from 'src/utils/logError';

function Auth({ children }) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const { user } = useSelector((state) => state.account);
  const query = useQuery();
  const bypassQuery = query.get('bypass');

  if (bypassQuery && bypassQuery === 'true') {
    Cookies.set('bypass', true, { expires: 0.1 });
  }

  useEffect(() => {
    const initAuthFirebase = async () => {
      // Check if user session is still active
      onAuthStateChanged(auth, async (result) => {
        let isAuthenticated = false;
        let accessToken = null;

        if (result) {
          const { uid } = result;
          isAuthenticated = true;

          if (!user) {
            try {
              // Get user information from database and update redux state
              const userInformation = await firestoreService.getUserInformation(uid);
              dispatch(setUserData({ ...userInformation, uid }));
            } catch (error) {
              dispatch(setUserData({}));
              dispatch(logout());
              logExceptionError(error);
            }
          }

          try {
            accessToken = await authService.getIdToken();

            if (accessToken) {
              authService.setSession(accessToken);
              dispatch(setAuthentication(isAuthenticated, accessToken));
              // authService.handleAuthenticationFirebase(accessToken);
            }
          } catch (error) {
            dispatch(setUserData({}));
            dispatch(logout());
            logExceptionError(error);
          }
        } else {
          try {
            dispatch(setUserData({}));
            dispatch(logout());
          } catch (error) {
            logExceptionError(error);
          }
        }

        setLoading(false);
      });
    };

    initAuthFirebase();
  }, [dispatch]);

  if (isLoading) return <SplashScreen />;

  return children;
}

Auth.propTypes = {
  children: PropTypes.any
};

export default Auth;

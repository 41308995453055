/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  RESET_LOCATION,
  UPDATE_LOCATION
} from 'src/actions/locationActions';

const initialState = {
};

const locationReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_LOCATION: {
      return produce(state, (draft) => {
        Object.keys(draft).forEach((key) => {
          draft[key] = null;
        });
      });
    }

    case UPDATE_LOCATION: {
      const { location } = action.payload;

      return produce(state, (draft) => {
        draft.location = null;

        Object.keys(location).forEach((key) => {
          draft[key] = location[key];
        });
      });
    }

    default: {
      return state;
    }
  }
};

export default locationReducer;

const mask = (value) => {
  if (!value || value === '') return '***';

  const regex = /\b(\w{2})(\w+)(\w)\b/g;

  return value.replace(
    regex,
    (_, first, middle, last) => `${first}${'*'.repeat(middle.length)}${last}`
  );
};

export default mask;

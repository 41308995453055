import firestoreService from 'src/services/firestoreService';
import authService from 'src/services/authService';
import logExceptionError from 'src/utils/logError';
import { instanceAxios as axios } from 'src/utils/axios';
import dayjs from 'dayjs';

export const ADD_SKILL = '@skill/add';
export const UPDATE_SKILL = '@skill/update';
export const UPDATE_SKILL_PUBLISH_STATUS = '@skill/update-publish-status';
export const GET_SKILL = '@skill/get';
export const GET_SKILLS = '@skill/get-skills';

export function addSkill(skillInformation) {
  return async (dispatch) => {
    try {
      const uid = await authService.getUserId();

      const skillId = await firestoreService.addSkill(skillInformation, uid);

      if (skillId) {
      // Adding Skill ID
        const added = { ...skillInformation, skillId };

        dispatch({
          type: ADD_SKILL,
          payload: {
            added
          }
        });
      }

      return skillId;
    } catch (error) {
      logExceptionError(error);

      throw error;
    }
  };
}

export function updateSkill(skillInformation, skillId) {
  return async (dispatch) => {
    try {
      const uid = await authService.getUserId();

      const skillUpdated = await firestoreService.updateSkill(skillInformation, skillId, uid);

      if (skillUpdated) {
        const skill = skillInformation;

        dispatch({
          type: UPDATE_SKILL,
          payload: {
            skill
          }
        });
      }
    } catch (error) {
      logExceptionError(error);

      throw error;
    }
  };
}

export function updateSkillPublishStatus(skillId, flag) {
  return async (dispatch) => {
    try {
      const response = await firestoreService.updateSkillPublishStatus(skillId, flag);

      if (response) {
        const skill = flag;

        dispatch({
          type: UPDATE_SKILL_PUBLISH_STATUS,
          payload: {
            skill
          }
        });

        return true;
      }

      return false;
    } catch (error) {
      logExceptionError(error);

      throw error;
    }
  };
}

export function getSkill(skillId) {
  return async (dispatch) => {
    try {
      const skill = await firestoreService.getSkill(skillId);

      dispatch({
        type: GET_SKILL,
        payload: {
          skill
        }
      });

      return skill;
    } catch (error) {
      logExceptionError(error);

      throw error;
    }
  };
}

/**
 * Get list of skills from either owned by self or others
 * @param {string} [ownership=null] - The ownership type, either 'self', 'others' or null (default).
 * @return {Array} List of retrived skills and can be empty [].
 */
export function getSkills(ownership = null) {
  return async (dispatch) => {
    try {
      const userId = await authService.getUserId();
      let skillsCollection = [];

      if (ownership === 'self') {
        skillsCollection = await firestoreService.getMySkills(userId);
      } else if (ownership === 'others') {
        skillsCollection = await firestoreService.getOtherSkills(userId);
      } else {
        skillsCollection = await firestoreService.getPublishedSkills();
      }

      if (skillsCollection) {
        dispatch({
          type: GET_SKILLS,
          payload: {
            skills: skillsCollection
          }
        });
      }

      return skillsCollection;
    } catch (error) {
      logExceptionError(error);

      throw error;
    }
  };
}

/**
 * Add new learning session request and send email to skill owner about the new session request
 * @param {Object} [information] - The session request information from the requestor
 * @return {string|null} The new session ID, or null if the new session cannot be created
 */
export async function addLearningSession(information) {
  try {
    const sessionId = await firestoreService.addLearningSession(information);

    if (sessionId) {
      const { host } = window.location;
      const sessionUrl = `https://${host}/session/details/${sessionId}`;
      const { startTime, endTime, ...rest } = information;

      const sessionDetails = {
        startTime: dayjs(startTime).format('D MMM YYYY HH:mm ZZ'),
        endTime: dayjs(endTime).format('D MMM YYYY HH:mm ZZ'),
        sessionUrl,
        ...rest
      };

      axios.post('/api/sessions/email-request', { ...sessionDetails });
    }

    return sessionId;
  } catch (error) {
    logExceptionError(error);

    throw error;
  }
}

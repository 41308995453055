/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation, matchPath } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box, // Chip,
  Divider, Drawer, Hidden, Link, List, ListSubheader, Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  Calendar as CalendarIcon,
  Feather as FeatherIcon,
  MessageCircle as MessageCircleIcon,
  PieChart as PieChartIcon,
  Book as BookIcon,
  Target as TargetIcon
} from 'react-feather';
import { v4 as uuidv4 } from 'uuid';
import Logo from 'src/components/Logo';
import logAnalyticsEvent from 'src/utils/logAnalyticsEvent';
import NavItem from './NavItem';

const navConfig = [
  {
    // subheader: 'Applications',
    items: [
      {
        title: 'Dashboard',
        icon: PieChartIcon,
        href: '/reports/dashboard'
      },
      {
        onboarding: 'teach',
        title: 'Teach',
        href: '/teach',
        icon: FeatherIcon,
        items: [
          {
            title: 'View my skills',
            href: '/teach/browse'
          },
          {
            title: 'Teach a skill',
            href: '/teach/create-skill'
          }
        ]
      },
      {
        onboarding: 'learn',
        title: 'Learn',
        href: '/learn',
        icon: BookIcon,
        items: [
          {
            title: 'Browse skills',
            href: '/learn/browse'
          }
        ]
      },
      {
        title: 'Sessions',
        href: '/sessions',
        icon: TargetIcon,
        // info: () => (
        //   <Chip
        //     color="secondary"
        //     size="small"
        //     label="Updated"
        //   />
        // )
      },
      {
        title: 'Chat',
        href: '/chat',
        icon: MessageCircleIcon,
        // info: () => (
        //   <Chip
        //     color="secondary"
        //     size="small"
        //     label="Updated"
        //   />
        // )
      },
      {
        title: 'Calendar',
        href: '/calendar',
        icon: CalendarIcon
      },
    ]
  }
];

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth = 0
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
        data-onboarding={item.onboarding}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
        data-onboarding={item.onboarding}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

function handleHelpLink() {
  // window.Tawk_API.toggle();
  logAnalyticsEvent('click', {
    link_name: 'Help Nav Bar'
  });
  window.open('https://skillexchange.tawk.help');
}

function NavBar({ openMobile, onMobileClose, }) {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          {navConfig.map((config) => (
            <List
              key={config.subheader || uuidv4()}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {config.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({ items: config.items, pathname: location.pathname })}
            </List>
          ))}
        </Box>
        <Divider />
        <Box p={2}>
          <Box
            p={2}
            borderRadius={1}
            bgcolor="background.dark"
            data-onboarding="provide-feedback"
          >
            <Typography
              variant="h6"
              color="textPrimary"
            >
              Any question?
            </Typography>
            <Link
              variant="subtitle1"
              color="secondary"
              component={RouterLink}
              onClick={handleHelpLink}
              to="#"
            >
              Help & feedback
            </Link>
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;

/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  SILENT_LOGIN,
  UPDATE_PROFILE,
  DELETE_AVATAR,
  RESET_PASSWORD,
  RESET_PASSWORD_FAILURE,
  AUTH_STATE_CHANGED
} from 'src/actions/accountActions';

const initialState = {
  user: null
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.user = null;
      });
    }

    case LOGIN_SUCCESS: {
      const { user } = action.payload;

      return produce(state, (draft) => {
        Object.keys(user).forEach((key) => {
          draft.user[key] = user[key];
        });
      });
    }

    case LOGIN_FAILURE:
    case RESET_PASSWORD_FAILURE: {
      return produce(state, (draft) => {
        draft.user = null;
        // Maybe store error as well?
      });
    }

    case LOGOUT:
    case RESET_PASSWORD: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case SILENT_LOGIN: {
      const { user } = action.payload;

      return produce(state, (draft) => {
        draft.user = user;
      });
    }

    case UPDATE_PROFILE: {
      const { user } = action.payload;

      return produce(state, (draft) => {
        Object.keys(user).forEach((key) => {
          draft.user[key] = user[key];
        });
      });
    }

    case DELETE_AVATAR: {
      return produce(state, (draft) => {
        draft.user.avatar = null;
      });
    }

    case AUTH_STATE_CHANGED: {
      const { accessToken, isAuthenticated } = action.payload.user;

      return produce(state, (draft) => {
        draft.user.isAuthenticated = isAuthenticated;
        draft.user.accessToken = accessToken;
      });
    }

    default: {
      return state;
    }
  }
};

export default accountReducer;

import { instanceAxios as axios } from 'src/utils/axios';
import LogRocket from 'src/utils/logrocket';
import logExceptionError from 'src/utils/logError';

/**
   * Retrieve country currency code and symbol
   * @param {string} [country] - The country name (for example, Australia)
   * @return {Object} Currency result as an object ( {code:string|null, symbol:string|null} )
   */

const getCountryCurrency = async (country) => {
  const currencyResult = {
    code: null,
    symbol: null
  };

  try {
    if (!country) return currencyResult;

    const restCountriesResponse = await axios.get(`https://restcountries.com/v3.1/name/${country}?fields=currencies`,
      {
        transformRequest: (data, headers) => {
          const header = headers;
          delete header.common.Authorization;
        }
      });

    const currency = restCountriesResponse.data[0].currencies;

    if (currency !== null) {
      const code = Object.keys(currency)[0];
      const { symbol } = currency[code];

      if (code) {
        currencyResult.code = code;
      } else {
        LogRocket.captureMessage('currency code not found', {
          tags: {
            country,
          }
        });
      }

      if (symbol) {
        currencyResult.symbol = symbol;
      } else {
        LogRocket.captureMessage('currency symbol not found', {
          tags: {
            country,
          }
        });
      }
    } else {
      LogRocket.captureMessage('currency not found', {
        tags: {
          country
        }
      });
    }

    return currencyResult;
  } catch (error) {
    logExceptionError(error);

    return currencyResult;
  }
};

export default getCountryCurrency;

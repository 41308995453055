import React, {
  useRef,
  useState
} from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  Avatar, Box, ButtonBase, Hidden, Menu, MenuItem, Typography
} from '@mui/material';
import { logout } from 'src/actions/accountActions';
import logAnalyticsEvent from 'src/utils/logAnalyticsEvent';
import logExceptionError from 'src/utils/logError';

const classes = {
  avatar: {
    height: 32,
    width: 32,
    marginRight: 1
  },
  popover: {
    width: 200
  }
};

function Account() {
  const history = useHistory();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await dispatch(logout());

      logAnalyticsEvent('logout');
      history.push('/');
    } catch (error) {
      logExceptionError(error);
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Avatar
          alt="User"
          sx={classes.avatar}
          src={account.user.avatar}
        />
        <Hidden mdDown>
          <Typography
            data-private
            variant="h6"
            color="inherit"
          >
            {`${account.user.firstName} ${account.user.lastName}`}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        sx={classes.popover}
        anchorEl={ref.current}
        open={isOpen}
      >
        {/* <MenuItem
        component={RouterLink}
        to="/social/profile"
      >
        Profile
      </MenuItem> */}
        <MenuItem
          component={RouterLink}
          to="/account"
        >
          Account
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}

export default Account;

import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

if (process.env.NODE_ENV === 'production') {
  LogRocket.init(process.env.REACT_APP_LOGROCKET_APP_ID, {
    release: process.env.REACT_APP_VERSION,
    rootHostname: 'skill.exchange',
    dom: {
      inputSanitizer: true,
    },
  });

  setupLogRocketReact(LogRocket);
}

export default LogRocket;
